import {
  ThemeProps as Prop,
  ColorProfiles,
} from '@powdr/constants';

import { componentThemesDefault } from './shared-theme-data';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#241F19',
    [Prop.CONTENT]: '#241F19',
    [Prop.LINK_TXT]: '#18D5EE',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
    [Prop.BTN_BG]: '#DAFF01',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#241F19',
    [Prop.BTN_TXT_HOVER]: '#18D5EE',
    [Prop.BTN_BG_ACTIVE]: '#18D5EE',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#241F19',
    [Prop.BTN_TXT_INACTIVE]: '#BBC2C6',
    [Prop.ELEMENT]: '#18D5EE',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#241F19',
    [Prop.ICON]: '#18D5EE',
    [Prop.ICON_HOVER]: '#241F19',
    [Prop.ICON_2]: '#18D5EE',
    [Prop.ICON_HOVER_2]: '#241F19',
    [Prop.HEADER_BG]: '#18D5EE',
    [Prop.HEADER_TXT]: '#241F19',
    [Prop.HEADER_ICON]: '#241F19',
    [Prop.HEADER_BG_2]: '#18D5EE',
    [Prop.HEADER_TXT_2]: '#241F19',
    [Prop.HEADER_ICON_2]: '#241F19',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#18D5EE',
    [Prop.HEADER]: '#241F19',
    [Prop.CONTENT]: '#241F19',
    [Prop.LINK_TXT]: '#241F19',
    [Prop.LINK_TXT_HOVER]: '#DAFF01',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#DAFF01',
    [Prop.BTN_TXT_HOVER]: '#241F19',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#241F19',
    [Prop.BTN_TXT_INACTIVE]: '#BBC2C6',
    [Prop.ELEMENT]: '#FFFFFF',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#241F19',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#241F19',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.HEADER]: '#18D5EE',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#DAFF01',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
    [Prop.BTN_BG]: '#18D5EE',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#FFFFFF',
    [Prop.BTN_TXT_HOVER]: '#241F19',
    [Prop.BTN_BG_ACTIVE]: '#18D5EE',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#BBC2C6',
    [Prop.BTN_TXT_INACTIVE]: '#241F19',
    [Prop.ELEMENT]: '#18D5EE',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#18D5EE',
    [Prop.ICON]: '#18D5EE',
    [Prop.ICON_HOVER]: '#DAFF01',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#BBC2C6',
    [Prop.HEADER]: '#241F19',
    [Prop.CONTENT]: '#241F19',
    [Prop.LINK_TXT]: '#241F19',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
    [Prop.BTN_BG]: '#18D5EE',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#DAFF01',
    [Prop.BTN_TXT_HOVER]: '#241F19',
    [Prop.BTN_BG_ACTIVE]: '#18D5EE',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#241F19',
    [Prop.BTN_TXT_INACTIVE]: '#BBC2C6',
    [Prop.ELEMENT]: '#18D5EE',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#241F19',
    [Prop.ICON]: '#241F19',
    [Prop.ICON_HOVER]: '#18D5EE',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#DAFF01',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
    [Prop.BTN_BG]: '#DAFF01',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#FFFFFF',
    [Prop.BTN_TXT_HOVER]: '#241F19',
    [Prop.BTN_BG_ACTIVE]: '#DAFF01',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#BBC2C6',
    [Prop.BTN_TXT_INACTIVE]: '#241F19',
    [Prop.ELEMENT]: '#DAFF01',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#DAFF01',
    [Prop.ICON]: '#DAFF01',
    [Prop.ICON_HOVER]: '#18D5EE',
  },
  ...componentThemesDefault,
};
