import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const woodwardTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#b4b4b4',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#e0e0e0',
    [Prop.ICON]: '#ffd200',
    [Prop.ICON_HOVER]: '#000000',
    [Prop.ICON_2]: '#ffd200',
    [Prop.ICON_HOVER_2]: '#000000',
    [Prop.HEADER_BG]: '#ffd200',
    [Prop.HEADER_TXT]: '#000000',
    [Prop.HEADER_ICON]: '#FFFFFF',
    [Prop.HEADER_BG_2]: '#000000',
    [Prop.HEADER_TXT_2]: '#ffd200',
    [Prop.HEADER_ICON_2]: '#FFFFFF',
    [Prop.ODD_EVEN_OFFSET]: '#e0e0e0',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#3a3a3a',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffd200',
    [Prop.LINK_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#757575',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#ffffff',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#696969',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#ffd200',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#4d4d4d',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#ffd200',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#ffd200',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#3a3a3a',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffd200',
    [Prop.LINK_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#757575',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#ffffff',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#696969',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFD200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#A29B6B',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
};

export const componentThemesDefault = {
  // required for WW theme takeover
  [Components.ALERT_BAR]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.HEADER]: '#18D5EE',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#DAFF01',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
    [Prop.BTN_BG]: '#18D5EE',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#FFFFFF',
    [Prop.BTN_TXT_HOVER]: '#241F19',
    [Prop.ICON]: '#18D5EE',
    [Prop.ICON_HOVER]: '#DAFF01',
  },

  // required for WW theme takeover
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#241F19',
    [Prop.NAV_ITEM_TXT_SOLID]: '#241F19',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#241F19',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#18D5EE',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#18D5EE',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },

  // required for WW theme takeover
  [Components.WEATHER_FEED]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.ICON]: '#DAFF01',
    [Prop.CONTENT]: '#FFFFFF',
  },

  // required for WW theme takeover
  [Components.DOR]: {
    [Prop.ICON]: '#18D5EE',
    [Prop.LINK_TXT]: '#18D5EE',
    [Prop.LINK_TXT_HOVER]: '#241F19',
    [Prop.BORDER]: '#18D5EE',
    [Prop.COMPONENT_BORDER]: '#241F19',
  },

  // required for WW theme takeover
  [Components.PROMOTIONS_FEED]: {
    [Prop.BACKGROUND]: '#18D5EE',
    [Prop.LINK_TXT]: '#241F19',
  },

  [Components.PROMOTIONS]: {
    [Prop.PROMO_ITEM_BG]: '#18D5EE',
    [Prop.PROMO_ITEM_HEADER]: '#241F19',
    [Prop.PROMO_ITEM_CONTENT]: '#241F19',
    [Prop.PROMO_ITEM_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_ITEM_BTN_TXT]: '#241F19',
    [Prop.PROMO_ITEM_BTN_BG_HOVER]: '#DAFF01',
    [Prop.PROMO_ITEM_BTN_TXT_HOVER]: '#241F19',
  },

  // required for WW theme takeover
  [Components.MEGA_NAV]: {
    [Prop.LINK_TXT]: '#241F19',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
    [Prop.BORDER]: '#241F19',
  },

  // required for WW theme takeover
  [Components.ECOMMERCE_BANNER]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.LINK_TXT]: '#DAFF01',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
    [Prop.TOGGLE_ACTIVE]: '#C4C4BF',
    [Prop.TOGGLE_INACTIVE]: '#C4C4BF',
    [Prop.ELEMENT]: '#18D5EE',
    [Prop.LINK_SEPARATOR]: '#5a5b5c',
  },

  // required for WW theme takeover
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#18D5EE',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#18D5EE',
    [Prop.CLOSE_BTN_ICON]: '#241F19',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#241F19',
    [Prop.MAIN_NAV_BORDER]: '#241F19',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#18D5EE',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#241F19',
    [Prop.SUB_NAV_BG]: '#BBC2C6',
    [Prop.SUB_NAV_TXT]: '#241F19',
    [Prop.SUB_NAV_BORDER]: '#241F19',
  },

  // required for WW theme takeover
  [Components.FOOTER]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.HEADER]: '#18D5EE',
    [Prop.BTN_BG]: '#18D5EE',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#FFFFFF',
    [Prop.BTN_TXT_HOVER]: '#241F19',
  },

  // required for WW theme takeover
  [Components.FOOTER_NAV]: {
    [Prop.LINK_TXT]: '#DAFF01',
    [Prop.LINK_TXT_HOVER]: '#18D5EE',
  },

  // required for WW theme takeover
  [Components.COPYRIGHT]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.LINK_TXT]: '#DAFF01',
  },
};
