import { mergeDeepLeft } from 'ramda';
import { css } from 'styled-components';

import {
  FontSize as FontSizes, FontFamilies, ComponentGroups,
} from '@powdr/constants';

import RefrigeratorDeluxeExtraBoldWoff from '../assets/fonts/Refrigerator-Deluxe-Extra-Bold.woff';
/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG20: '14px',
  REG30: '16px',
  REG40: '18px',
  REG50: '19px',
  MED20: '23px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {
  [ComponentGroups.HERO]: {
    title: '90px',
    'title-large-bp': '90px',
    'title-smaller-bp': '55px',
    copy: '26px',
    'copy-larger-bp': '30px',
    'copy-large-bp': '28px',
    'copy-smaller-bp': '22px',
  },
};

// For some reason, this font just won't load properly via the CSS version,
// have tried TONS of different fixes
// so this will be here for now until the font is replaced or we figure out a fix
const FontFaces = css`
  @font-face {
    font-family: 'Refrigerator Deluxe - Extra Bold';
    src:url(${RefrigeratorDeluxeExtraBoldWoff}) format('woff');
    font-display: swap;
  }
`;

const fonts = {
  // [FontFamilies.PRI_HDR_BLK]: 'font-family: "Soehne Schmal Fett"',
  // [FontFamilies.SEC_HDR_BLK]: 'font-family: "Soehne Schmal Fett"',
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "Refrigerator Deluxe - Extra Bold"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "Refrigerator Deluxe - Extra Bold"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "Gotham Bold"',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "Gotham Book"',
};

export const fontTheme = {
  fonts,
  FontSize,
  FontFaces,
  ComponentFontAdjustments,
};
